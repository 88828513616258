import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedin: false,
    user:{
      useremail:"",
      id:"",
      org:""
    }

    
  },
  getters: {},
  mutations: {
    setCurrentUserID(state, vid){
      state.user.id = vid
    },
    setCurrentUserEmail(state, vemail) {
      state.user.useremail = vemail;
    },
    setCurrentUserOrg(state, organisation) {
      state.user.org = organisation;
    },
    setCurrentState(state, allowed) {
      state.loggedin = allowed;
    }
  },
  actions: {},
  modules: {},
});
