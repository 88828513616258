<template lang="pug">
.main
  b-container.bodyContainer.py-3
    .mainBody
      b-modal#logOutBox(title="Log Out?" centered header-bg-variant="danger" hide-header-close header-text-variant="white")
        h4  Are you sure?
        template(#modal-footer)
            b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('logOutBox')") No
            b-button(variant="primary" size="md" class="float-right" to="/login") Yes

      b-modal#successBox(title="Successful" centered header-bg-variant="success" no-close-on-backdrop hide-header-close header-text-variant="white")
        h4  Submission Completed!
        template(#modal-footer)
            b-button(variant="primary" size="md" class="float-right" to="/login") OK

      b-modal#errorBox(title="Error" centered header-bg-variant="danger" no-close-on-backdrop hide-header-close header-text-variant="white")
        h4  Oops! There's seems to be an issue.
        template(#modal-footer)
            b-button(variant="primary" size="md" class="float-right" to="/login") OK

      b-modal#inputFilledBox(title="Warning" centered header-bg-variant="danger" no-close-on-backdrop hide-header-close header-text-variant="white")
        h4  Please enter all of the fields
        template(#modal-footer)
            b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('inputFilledBox')") Close

      .header.d-flex.justify-content-between.align-items-center.px-3.py-2
        h2 Volunteer Session
        b-button.navButton.bg-transparent.border-0.text-white(
          size="lg",
          @click="$bvModal.show('logOutBox')"
        )
          .h1
            b-icon(icon="box-arrow-right")
      .bodyInputForm.text-dark.p-2.m-3
        div.bodyInputForm(v-if="submitClicked")
          p.lead.text-dark.text-center.my-3 {{sending ? 'Sending Data...' : 'Submission Complete!' }}
          div.bodyInputForm.text-center
              lottie-vue-player.bodyInputForm(
                  :src="currentLottie"
                  :autoplay="true"
                  :loop="true"
                  :speed="1"
                  ref="lottie")
        div.text-center.h-100(v-if="!ready")
          b-spinner
        div(v-if="ready")
          b-form(@submit.prevent="submitForm" v-if="submitClicked == false")
            b-form-group.m-2(label="Volunteer Role:", label-size="md")
              b-form-select.p-2.rounded.w-100(
                v-model="formdata.crf69_membertype",
                :options="memberchoice",
                required
              )
            b-form-group.m-2(
              v-slot="{ ariaDescribedby }",
              label="Activity Type:",
              label-size="md"
            )
              .d-flex
                b-form-radio(
                  :aria-describedby="ariaDescribedby",
                  name="some-radios",
                  value="a",
                  v-model="selected",
                  required,
                  @change="revertforrec"
                ) Recurring Activities
                b-form-radio.mx-3(
                  :aria-describedby="ariaDescribedby",
                  name="some-radios",
                  value="b",
                  v-model="selected",
                  required
                ) Activities
            b-form-group.m-2(v-if="selected" label="Activity Name:", label-size="md")
              b-form-select.p-2.rounded.w-100(
                v-if="selected === 'a'",
                :options="recurringActivityListParsed",
                v-model="recurring_activity_id",
                ref="rec_id",
                required
              )
              b-form-select.p-2.rounded.w-100(
                v-else="selected === 'b'",
                :options="normalActivityListParsed",
                v-model="normal_activity_id",
                ref="act_id",
                required,
                @change="autofill_act"
              )
            b-form-group.m-2(label="Session Date:", label-size="md")
              b-form-datepicker.dateInput(
                v-model="rawdate",
                required,
                :disabled="noedit",
                @input="setAttTime"
              )
            b-form-group.m-2(label="Session Time:", label-size="md")
              input#timeinput.text-center.w-100.p-2.rounded(
                type="time",
                required,
                v-model="rawtime",
                :disabled="noedit",
                @input="setAttTime"
              )
            b-form-group.m-2(label="Session Duration (Hours):", label-size="md")
              vue-numeric-input.durationInput.w-100(
                controlsType="plusminus",
                :min="0",
                :step="0.5",
                :precision="2",
                v-model="rawduration",
                :disabled="noedit",
                @input="setAttTime"
              )
            b-form-group.m-2(label="Remarks (Put 'Nil' if none):", label-size="md")
              b-form-textarea.p-2.rounded.w-100(
                v-model="formdata.crf69_remarks",
                rows="4",
                required
              )
            b-button.submitButton.my-3.mx-auto.px-5(
              pill,
              size="md",
              @click="submitForm"
            ) Submit
</template>

<script>
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Singapore");
import axios from "axios";
export default {
  name: "SessionView",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      ready:false,
      sending:false,
      noedit: false,
      selected: "",
      submitClicked:false,
      vid: this.$store.state.user.id,
      oid: this.$store.state.user.org,
      volInfo: [],
      memberchoice:[],
      combineNormalActList:[],
      nonOrgNormalList:[],
      activityList: [],
      recurringList: [],
      recurring_activity_id: "",
      normal_activity_id: "",
      rawtime: "",
      rawdate: "",
      rawduration: "",
      formdata: {
        crf69_datetime: "",
        crf69_membertype: "",
        crf69_remarks: "",
        crf69_attendanceend: "",
      },
      picklists: {
        crf69_membertype2: [],
      },
    };
  },
  async mounted() {
    let { vid, oid } = this;
    let params = new URLSearchParams({ vid, oid });
    let arr = [
      axios.get("/api/session?" + params.toString()).then(({ data }) => {
        this.activityList = data.nact;
        this.recurringList = data.activity;
        this.volInfo = data.voldata[0]
        this.nonOrgNormalList = data.nonorgnormalact;
        return data;
      }),

      axios.get("/api/picklists/").then(({ data }) => {
        this.picklists = data;
        return data;
      }),
    ];
    Promise.all(arr)
      .then(() => {
        this.ready = true;
        this.combineBothNormalAct();
        this.filterMemberChoice();
      })
      .catch(() => {
        alert(
          "Unable to connect to the system. Please try your submission another time."
        );
      });
  },

  computed: {
    recurringActivityListParsed() {
      let output = this.recurringList
        .map((item) => {
          return {
            text: item.crf69_name,
            value: item.crf69_recurringactivityid,
          };
        })
        .filter((item) => !!item.value);

      return [
        {
          text: "Please select an activity",
          value: null,
          disabled: true,
        },
        ...output,
      ];
    },

    normalActivityListParsed() {
      let output = this.combineNormalActList
        .map((item) => {
          return {
            text: item.crf69_activityid,
            value: item.crf69_activitiesid,
          };
        })
        .filter((item) => !!item.value);

      return [
        {
          text: "Please select an activity",
          value: null,
          disabled: true,
        },
        ...output,
      ];
    },
  },
  methods: {
    combineBothNormalAct(){
      let arr = [...this.activityList, ...this.nonOrgNormalList];
      this.combineNormalActList = arr.filter(function({crf69_activitiesid}) {
        return !this.has(crf69_activitiesid) && this.add(crf69_activitiesid);
      }, new Set)
      console.log(this.combineNormalActList)
    },
    filterMemberChoice(){
        let mainRole
        let additionalRole
        if(!this.volInfo.crf69_role){
            mainRole = []
        }else{
            mainRole = [this.volInfo.crf69_role.toString()]
        }
        if(!this.volInfo.crf69_additionalroles){
            additionalRole = []
        }else{
            additionalRole = this.volInfo.crf69_additionalroles.split(',')
        }
        let allRoles = mainRole.concat(additionalRole)
        const LIFEMEMBER = this.picklists.crf69_membertype2[0]
        const OTHERMENTOR = this.picklists.crf69_membertype2[1]
        const OTHERVOL = this.picklists.crf69_membertype2[2]
        const CORPVOL = this.picklists.crf69_membertype2[3]
        console.log(allRoles)
        if (allRoles.includes('955870002')){
            this.memberchoice.push(LIFEMEMBER)
        }
        if (allRoles.includes('955870003')||allRoles.includes('955870005')){
            this.memberchoice.push(OTHERMENTOR)
        }
        if (allRoles.includes('955870001')||allRoles.includes('955870004')||allRoles.includes('955870000')||allRoles.includes('955870008')){
            this.memberchoice.push(OTHERVOL)
        }
        if (allRoles.includes('955870006')||allRoles.includes('955870007')){
            this.memberchoice.push(CORPVOL)
        }
        console.log("member choice:", this.memberchoice)
    },
    revertforrec() {
      this.noedit = false;
      if (this.rawdate != "") {
        this.rawdate = "";
      }
      if (this.rawtime != "") {
        this.rawtime = "";
      }
      if (this.rawduration != "") {
        this.rawduration = "";
      }
    },
    autofill_act() {
      let filteredNormalActivity = this.combineNormalActList.filter((activity) => {
        return activity.crf69_activitiesid == this.normal_activity_id;
      });
      let sg = dayjs.tz(
        filteredNormalActivity[0].crf69_scheduledon,
        "Asia/Singapore"
      ).$d;
      let sg_date = dayjs.tz(sg).format("YYYY-MM-DD");
      let sg_time = dayjs.tz(sg).format("HH:mm:ss");
      this.rawdate = sg_date;
      this.rawtime = sg_time;
      this.rawduration = filteredNormalActivity[0].crf69_duration / 60;
      this.noedit = true;
      this.setAttTime();
    },

    setAttTime() {
      if (!this.rawtime) {
        this.formdata.crf69_datetime = dayjs(
          this.rawdate,
          "YYYY-MM-DD"
        ).toISOString();
        return;
      }
      this.formdata.crf69_datetime = dayjs(
        `${this.rawdate} ${this.rawtime}`,
        "YYYY-MM-DD HH:mm:ss"
      ).toISOString();
      this.formdata.crf69_attendanceend = dayjs(this.formdata.crf69_datetime)
        .add(this.rawduration, "hours")
        .toISOString();
    },
    isInputFilled(){
      if(
        (this.formdata.crf69_membertype != "") &&
        (this.formdata.crf69_datetime != "") &&
        (this.formdata.crf69_remarks != "") &&
        (this.formdata.crf69_attendanceend !="") &&
        ((this.recurring_activity_id != "") || (this.normal_activity_id != ""))
      ){
        return true
      }
      return false
    },
    sendingNow(){
        if(!this.formdata.crf69_membertype||!this.formdata.crf69_datetime||!this.formdata.crf69_remarks||!this.rawtime||!this.rawdate||!this.rawduration){
            this.$bvModal.show('inputFilledBox')
            return;
        } else {
            this.submitClicked= true;
            this.currentLottie = "/lottie/sending.json";
            this.sending = true;
        }
    },

    async submitForm() {
      if (this.recurring_activity_id) {
        console.log("Submit Recurring Activity Attendance");
        let {vid, recurring_activity_id} = this
        let {crf69_datetime, crf69_membertype, crf69_remarks, crf69_attendanceend} = this.formdata
        let attendance_obj = {crf69_datetime, crf69_membertype, crf69_remarks, crf69_attendanceend}
        this.sendingNow();
        if (this.sending == true) {
          try {
            await axios.post("/api/session", {
              recurring_activity_id,
              attendance_obj,
              vid
            });
            this.currentLottie="/lottie/done.json";
            let lottie = this.$refs.lottie.$el.querySelector('lottie-player');
            lottie.load(this.currentLottie);
            this.sending = false;
            this.$bvModal.show('successBox')
            setTimeout(() => this.$router.push("/login"), 5000);
          } catch {
            this.$bvModal.show('errorBox')
          }
        }
      } else if (this.normal_activity_id) {
        console.log("Submit Normal Activity Attendance");
        let {normal_activity_id, vid} = this
        let {crf69_datetime, crf69_membertype, crf69_remarks, crf69_attendanceend} = this.formdata
        let attendance_obj = {crf69_datetime, crf69_membertype, crf69_remarks, crf69_attendanceend}
        this.sendingNow();
        if (this.sending == true) {
          try {
            await axios.post("/api/activity", {
              normal_activity_id,
              attendance_obj,
              vid
            });
            this.currentLottie="/lottie/done.json";
            let lottie = this.$refs.lottie.$el.querySelector('lottie-player');
            lottie.load(this.currentLottie);
            this.sending = false;
            this.$bvModal.show('successBox')
            setTimeout(() => this.$router.push("/login"), 5000);
          } catch {
            this.$bvModal.show('errorBox')
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.submitButton {
  display: block;
  background-color: #bb3d2f;
}

.dateInput {
  border-style: solid 1px black;
}
.header {
  background-color: #26263e;
  color: white;
}
.main {
  background-color: #26263e;
  min-height: 100vh;
}

.bodyInputForm {
  border-radius: 20px;
  background-color: #d9d9d9;
}

@media only screen and (max-width: 576px) {
  .bodyInputForm {
    height: 75%;
  }
  .radio{
    margin-left:0px !important; 
  }
}

@media only screen and (min-width: 768px) {
  .mainBody {
    margin-left: 64px;
    margin-right: 64px;
  }
  .bodyInputForm {
    padding: 3rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .mainBody {
    margin-left: 160px;
    margin-right: 160px;
  }
  .bodyInputForm {
    padding: 3rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .mainBody {
    margin-left: 160px;
    margin-right: 160px;
  }
  .bodyInputForm {
    padding: 3rem;
  }
}
</style>
