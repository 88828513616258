import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import SessionView from "../views/SessionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect:"/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/session",
    name: "session",
    component: SessionView
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
