<template lang="pug">
div.main
  b-container.bodyContainer
    //
    b-modal#invalidBox(title="Email Not Found" centered header-bg-variant="danger" hide-header-close header-text-variant="white")
      h4  Invalid Email. Please ensure the data being inputted is as registered.
      template(#modal-footer)
        b-button(variant="primary" size="md" class="float-right" @click="$bvModal.hide('invalidBox')") OK
    //
    b-modal#orgErrorBox(title="Warning" centered header-bg-variant="danger" hide-header-close header-text-variant="white")
      h4  Your account is not associated to any organisation, please contact LCSS for support.
      template(#modal-footer)
        b-button(variant="primary" size="md" class="float-right" @click="$bvModal.hide('orgErrorBox')") OK
    //
    b-modal#roleErrorBox(title="Warning" centered header-bg-variant="danger" hide-header-close header-text-variant="white")
      h4  Your account is not assigned to any role, please contact LCSS for support.
      template(#modal-footer)
        b-button(variant="primary" size="md" class="float-right" @click="$bvModal.hide('roleErrorBox')") OK
    div.mainBody
      .header.d-flex.justify-content-between.align-items-center.px-3.py-4
        b-img#logo.p-2.ml-5(src="/LCSS-wo-background.png" alt="LCSS" fluid)
        div.buttonGroup
          b-button(size="lg").navButton.bg-transparent.border-0.text-white Sign In 
      .bodyGreeting.text-white.mx-4.py-2
        p.title Welcome back,
        h5 Sign in to continue
      .bodyInputForm.text-dark.p-4.m-4
        div.text-center.h-100(v-if="!ready")
          b-spinner
        div.inputGroup.m-3(v-if="ready")
          label.h5 Email
          b-form-group
            b-input.p-2(placeholder="janedoe@example.com" size="lg" type="email" v-model="email")
            b-button.signInButton.my-3.p-3(block @click="verify" :disabled="!email") Verify &amp; Sign In
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
export default {
  name: "LoginView",
  data(){
    return{
      ready:true,
      dt:null,
      email:"",
      allowed: false
    }

  },

  methods:{
    async verify(){
      this.ready = false
      let temp = "";
      let temp2 = "";
      let {email} = this
      let params= new URLSearchParams({email});
      await axios.get("/api/login?"+params.toString()).then((result)=>{
        console.log(this.allowed)
        this.allowed = result.data.login
        if(this.allowed == true){
          temp = result.data.data.value[0].crf69_volunteerprofileid
          if(!result.data.data.value[0].crf69_Organization){
            this.$bvModal.show('orgErrorBox')
            this.ready = true
            return
          }
          if(!result.data.data.value[0].crf69_role && !result.data.data.value[0].crf69_additionalroles ){
            this.$bvModal.show('roleErrorBox')
            this.ready = true
            return
          }
          temp2 = result.data.data.value[0].crf69_Organization.crf69_externalorganizationid
          this.$store.commit('setCurrentUserEmail', {email});
          console.log(this.$store.state.user.useremail)
          this.$store.commit('setCurrentUserID', temp);
          this.$store.commit('setCurrentUserOrg', temp2)
          this.ready = true
          //console.log(name, email, temp, temp2)
          this.$router.push('/session');
        }
        else{
          this.$bvModal.show('invalidBox')
          this.ready = true
        }
      });
    }
  }

};
</script>

<style lang="scss" scoped>
  .title{
    font-size: 2.5rem;
  }
  .navButton:hover{
    text-decoration: underline;
  }
  .header{
    background-color: #26263e;
    color:white;
  }
  .main{
    background-color:#26263e;
    min-height: 100vh
  }

  .bodyInputForm{
    border-radius:20px;
    background-color: #D9D9D9 ;

  }
  .signInButton{
    display: block;
    width: 100%;
    background-color: #BB3D2F;
  }

  #logo{
    border-radius: 20px;
    background-color: #D9D9D9;
    width: 6rem;
    height: 6rem;
  }

  @media only screen and (max-width: 576px) {
    .title{
      font-size:2rem;
    }

    #logo{
      border-radius: 20px;
      background-color: #D9D9D9;
      width: 4rem;
      height: 4rem;
    }
  }

  @media only screen and (min-width: 768px) {
    .mainBody{
      margin-left: 64px;
      margin-right:64px;
    }
    .bodyInputForm{
      padding:3rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .mainBody{
      margin-left: 160px;
      margin-right: 160px;
    }
    .bodyInputForm{
      padding:3rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    .mainBody{
      margin-left: 160px;
      margin-right: 160px;
    }
    .bodyInputForm{
      padding:3rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    .mainBody{
      margin-left: 240px;
      margin-right: 240px;
    }
  }
  


</style>
